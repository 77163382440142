import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import PostLayout from '../../components/PostLayout';
import IconTitle from '../../components/IconTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getCategoryColor from '../../utils/getCategoryColor';
import options from '../../utils/getRichTextOptions';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import WorldMarkets from '../../assets/icons/icon-WorldMarkets.svg';
import USA from '../../assets/icons/usa-flag.svg';
import CHINA from '../../assets/icons/china-flag.svg';
import UK from '../../assets/icons/uk-flag.svg';
import Euro from '../../assets/icons/eurozona-flag.svg';
import JPN from '../../assets/icons/japon-flag.svg';
import useIsClient from '../../utils/isClient';
import { HeadApi } from '../../components/HeadApi';
import parseDate from '../../utils/parseDate';



export const Head = ({ location }) => {

    const slug = location.pathname.replace("/internacional/","");
    console.log("[HEAD SLUG]", slug);

    const [post, setPost] = useState({});

    useEffect(() => {

        const uri_api = "https://cdn.contentful.com/spaces/rmz81tjgnatt/environments/master/entries?access_token=C8oMOx_DUPRr2BbTdEC4Cg3zgXjWVPDlpcfDVt1pyiE&content_type=diarioInternacional&fields.slug[match]=" + slug;
        
        fetch(uri_api, {
            method: 'GET',
            redirect: 'follow'
        })
        .then(response => response.json())
        .then(result => {          

            const fields = result.items[0].fields;
            const assets = result.includes.Asset;
            
            const hero = assets.find( (heroImage:any) => heroImage.sys.id == fields.heroImage.sys.id );

            setPost({
                title:fields.titulo,
                description : fields.resumen,
                fluid : hero.fields.file.url,
            });

        })
        .catch(error => console.log('error', error));
    }, []);


    return(
        <HeadApi
            title={post.title}
            description={post.description}
            img={post.fluid}
        />
    );
};

export default function DiarioInternacional({ slug }): JSX.Element {

    const [post, setPost] = useState(Object);
    
    useEffect(() => {

        const uri_api = "https://cdn.contentful.com/spaces/rmz81tjgnatt/environments/master/entries?access_token=C8oMOx_DUPRr2BbTdEC4Cg3zgXjWVPDlpcfDVt1pyiE&content_type=diarioInternacional&fields.slug[match]=" + slug;
        
        fetch(uri_api, {
            method: 'GET',
            redirect: 'follow'
        })
        .then(response => response.json())
        .then(result => {

            const data = {
                tags: null || [] as any,
                diarioInternacionalPais: null || [] as any
            }
            
            console.log("[Results]",result);
            const items = result.items[0];
            const fields = result.items[0].fields;
            const assets = result.includes.Asset;
            const entrys = result.includes.Entry;
            const sys = result.items[0].sys;
            
            console.log("[Fields]",fields);
            
            const hero = assets.find( (heroImage:any) => heroImage.sys.id == fields.heroImage.sys.id );
            const autor = entrys.find( (autor:any)=>autor.sys.id == fields.autor.sys.id );
            
            console.log("[AUTOR]",autor);
            console.log("[HERO IMAGE]",hero);

            if(items.fields.tags){
                for (let index = 0; index < items.fields.tags.length; index++) {
                    let find_tag = entrys.find( (tag:any) => tag.sys.id == items.fields.tags[index].sys.id )
                    data.tags.push({
                        nombre: find_tag.fields.nombre,
                        slug: find_tag.fields.slug
                    })
                }
            }else{
                data.tags = null;
            }

            if(items.fields.diarioInternacionalPais){
                for (let index = 0; index < items.fields.diarioInternacionalPais.length; index++) {
                    let find_diarioInternacionalPais = entrys.find( (diarioInternacionalPais:any) => diarioInternacionalPais.sys.id == items.fields.diarioInternacionalPais[index].sys.id );

                    console.log("[DIARIO INTERNACIONAL]", find_diarioInternacionalPais);
                    data.diarioInternacionalPais.push({
                        subheaderPais: find_diarioInternacionalPais.fields.subheaderPais,
                        textoPais: find_diarioInternacionalPais.fields.textoPais
                    });
                }
            }else{
                data.diarioInternacionalPais = null;
            }

            setPost({
                title:fields.titulo,
                description : fields.resumen,
                categorySlug : 'internacional',
                contentSlug: 'diario-internacional',
                category : 'Internacional',
                content: 'Diario Internacional',
                // content : sys.contentType.sys.id,
                slug : fields.slug,
                date : parseDate(sys.createdAt),
                fluid : hero.fields.file.url,
                author : autor.fields.nombre,
                authorObject : autor.fields,
                tags : data.tags,
                textEUA: fields.textoEUA,
                textEurozona: fields.textoEurozona,
                textUK: fields.textoUK,
                textJpn: fields.textoJapn,
                textChina: fields.textoChina,
                diarioInternacionalPaises : data.diarioInternacionalPais
            });
        })
        .catch(error => console.log('error', error));
    }, []);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const color = getCategoryColor('internacional');

  // console.log('post diario econ');
  console.log("POST COMPONENT PAGE",post);

  const isClient = useIsClient();

  if(isClient){

    return (
      <>
        <PostLayout post={post} color={color}>
          <>
            {post?.description && (
              <h2
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  fontSize: '1.15em',
                }}
              >
                {post?.description}
              </h2>
            )}
            <div>
              <ul>
                {/* <li>
                  <a href="#usa-section">usa</a>
                </li> */}
                {/* <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li> */}
              </ul>
            </div>
            <>
              <Grid container>
                
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={mobile ? { zIndex: 0, background: 'white' } : {}}
                >
                  <div id="usa-section">
                    {post.textEUA && (
                      <IconTitle title="Estados Unidos" icon={USA}>
                        {documentToReactComponents(post.textEUA, options)}
                      </IconTitle>
                    )}
                  </div>
  
                  {post.imagesEUA && (
                    <div style={{ marginTop: '30px', marginBottom: '30px', width:"50%" }}>
                      <img src={post.imagesEUA.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
                  {post.textEurozona && (
                    <IconTitle title="Eurozona" icon={Euro}>
                      {documentToReactComponents(post.textEurozona, options)}
                    </IconTitle>
                  )}
  
                  {post.imagesEurozona && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      <img src={post.imagesEurozona.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
                  {post.textUk && (
                    <IconTitle title="Reino Unido" icon={UK}>
                      {documentToReactComponents(post.textUk, options)}
                    </IconTitle>
                  )}
                  {post.imagesUk && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      <img src={post.imagesUk.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
                  {post.textJpn && (
                    <IconTitle title={"Japón"} icon={JPN}>
                      {documentToReactComponents(post.textJpn, options)}
                    </IconTitle>
                  )}
                  {post.imagesJpn && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      <img src={post.imagesJpn.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
                  {post.textChina && (
                    <IconTitle title="China" icon={CHINA}>
                      {documentToReactComponents(post.textChina, options)}
                    </IconTitle>
                  )}
  
                  {post.imagesChina && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      <img src={post.imagesChina.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
                  {post.diarioInternacionalPaises && 
                    
                    post.diarioInternacionalPaises.map(function (node:any) {
                      return (
                        <>
                          <IconTitle title={node.subheaderPais} icon={WorldMarkets}>
                            {documentToReactComponents(node.textoPais, options)}
                          </IconTitle>
                        
                        { node.imagenPais &&
                          <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                            <img src={node.imagenPais.gatsbyImageData.images.fallback.src} alt="" />
                          </div>
                        }
                        </>
                      );
                    })
                  }
                  
                </Grid>
              </Grid>
            </>
          </>
        </PostLayout>
      </>
    );
  }else{
    return(<></>);
  }

}